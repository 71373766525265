.acquila {
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    max-width: 0px;
    height: 60%;
    /**
    border-right: 1px solid whitesmoke;
    border-left: 1px solid grey;
    **/
};

.string-canvas {
    z-index: 3;
    display:block;
    position: absolute;
}