.fret {
    width: 25%;
    min-height: 5vh;
    max-height: 16vh;
    border-bottom: 5px solid #424036;
    background-image: url("../style/fret.jpg");
    background-size: contain;
}


.fret-open {
    width: 25%;
    border-bottom: 10px solid #424036;
    margin: 0;
    min-height: 20vh;
    z-index: 5;
    overflow: hidden;   
}

.note-strum {
    font-size: 0.65em;
    font-weight: 700;
}

.note-fret {
    font-size: 0.9em;
}
